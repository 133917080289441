import React from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"

import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import InViewTrigger from "../../DefinitionComponents/Utility/UX/InViewTrigger"

function ScrollcompontentListelement({ title, text1, text2, sizevariant }) {
  return (
    <Box mb={20} w={{ base: "100%", md: "90%" }}>
      <InViewTrigger threshold={0.4} triggerOnce={false}>
        {inView => (
          <Flex
            flexWrap="wrap"
            direction={{ base: "column", md: "row", lg: "row" }}
          >
            <Box
              w={{ base: "100%", md: "35%" }}
              pr={4}
              minW={{ base: "90%", md: "35%" }}
              maxW={{ base: "90%", md: "35%" }}
            >
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  fontSize={{ base: "3xl", lg: "5xl" }}
                  fontWeight="normal"
                  color="brand.tan.light"
                  mb="4"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  {title}
                </Heading>
              </MaskSlidInAnimation>
            </Box>
            <Box
              w={{ base: "100%", md: "65%" }}
              maxW={{ base: "100%", md: "65%" }}
            >
              <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                <Heading
                  fontSize={{ base: "2xl", lg: "3xl" }}
                  fontWeight="normal"
                  mb="4"
                  color="brand.tan.primary"
                >
                  {text1}
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                <Heading
                  fontSize={{ base: "md", md: "lg" }}
                  fontWeight="normal"
                >
                  {text2}
                </Heading>
              </FadeInAnimation>
            </Box>
          </Flex>
        )}
      </InViewTrigger>
    </Box>
  )
}

export default ScrollcompontentListelement
